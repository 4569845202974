<template>
    <b-row>
        <b-col>
            <b-card>
                <b-card-header>
                    <h4 class="text-center">Brief Konten</h4>
                </b-card-header>
                <b-card-body>
                    <a href="javascript:;" @click="back()">Back</a>
                    <hr>
                    <validation-observer
                        ref="step3"
                    >
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="step3"
                            >
                            <b-row>
                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Tanggal Deadline Konten"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Tanggal Deadline Konten"
                                            vid="deadline-datepicker"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >                                            
                                            <b-form-datepicker
                                                id="deadline-datepicker"
                                                name="deadline-datepicker"
                                                :state="(errors.length > 0 || contentDeadlineErrMsg) ? false:null"
                                                v-model="contentDeadline"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                title-format="DD-MM-YYYY"
                                                class="mb-0"
                                                :min="deadlineMinDate"
                                                />
                                            <small class="text-danger">{{ errors[0] || contentDeadlineErrMsg }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Tanggal Campaign Dimulai"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Tanggal Campaign Dimulai"
                                            vid="start-datepicker"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-datepicker
                                                id="start-datepicker"
                                                name="start-datepicker"
                                                v-model="startDate"
                                                class="mb-0"
                                                :state="errors.length > 0 ? false:null"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                title-format="DD-MM-YYYY"
                                                :min="minStartDate"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Tanggal Campaign Selesai"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Tanggal Campaign Selesai"
                                            vid="end-datepicker"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-datepicker
                                                id="end-datepicker"
                                                name="end-datepicker"
                                                :state="errors.length > 0 ? false:null"
                                                v-model="endDate"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                title-format="DD-MM-YYYY"
                                                :min="minEndDate"
                                                class="mb-0"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Brief/Deskripsi Konten"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Brief/Deskripsi Konten"
                                            vid="description"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-textarea
                                                id="description"
                                                name="description"
                                                :state="errors.length > 0 ? false:null"
                                                v-model="description"
                                                class="mb-0"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Tipe Konten"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Tipe Konten"
                                            vid="contentType"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-radio-group
                                                id="contentType"
                                                name="contentType"
                                                v-model="contentType"
                                                :options="optionsRadio"
                                                :state="errors.length > 0 ? false:null"
                                                class="demo-inline-spacing"
                                                >
                                            </b-form-radio-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Apakah Kamu Memerlukan Kirim Barang ke Influencer?"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Kirim Barang"
                                            vid="sample"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-radio-group
                                                id="sample"
                                                name="sample"
                                                v-model="sample"
                                                :options="optionsRadioKirim"
                                                :state="errors.length > 0 ? false:null"
                                                class="demo-inline-spacing"
                                                >
                                            </b-form-radio-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12" v-show="sample">
                                    <!-- basic -->
                                    <b-form-group
                                        label="No. Resi"
                                        label-for="basicInput"
                                    >
                                        <b-form-input
                                            v-model="receiptNumber"
                                            class="mb-1"
                                            />
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Aturan Foto/Video"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Aturan Foto/Video"
                                            vid="mediaGuideline"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-textarea
                                                id="mediaGuideline"
                                                name="mediaGuideline"
                                                :state="errors.length > 0 ? false:null"
                                                v-model="mediaGuideline"
                                                class="mb-0"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Aturan Caption"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Aturan Caption"
                                            vid="captionGuideline"
                                            rules="required"
                                            :custom-messages="customMessages"
                                            >
                                            <b-form-textarea
                                                id="captionGuideline"
                                                name="captionGuideline"
                                                :state="errors.length > 0 ? false:null"
                                                v-model="captionGuideline"
                                                class="mb-0"
                                                />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                
                                <b-col cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Referensi Foto/Video"
                                        label-for="basicInput"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Referensi Foto/Video"
                                            vid="reference"
                                            >
                                            <b-form-input
                                                id="reference"
                                                name="reference"
                                                :state="(errors.length > 0 || referenceErrMsg) ? false:null"
                                                v-model="reference"
                                                />
                                            <small><i>Silahkan upload dokumen brief konten, referensi video dan foto dalam satu link google drive</i></small>
                                            <small class="text-danger"><br/>{{ errors[0] || referenceErrMsg }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col class="mb-1" cols="12">
                                    <!-- basic -->
                                    <b-form-group
                                        label="Informasi Lainnya (Optional)"
                                        label-for="basicInput"
                                    >
                                        <b-form-textarea
                                            v-model="additionalInfo"
                                            />
                                        <small><i>Informasi lainnya hanya dapat dilihat oleh admin PT DMMX RANS DIGITAL</i></small>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-button
                                        type="submit"
                                        block
                                        variant="primary"
                                        >
                                        Selanjutnya
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BFormTextarea,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormInput
} from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from '@axios'

export default {
    name: 'CampaignCreateStep3',
    components: {
        vueDropzone: vue2Dropzone,

        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormGroup,
        BFormSelect,
        BFormDatepicker,
        BFormTextarea,
        BFormRadioGroup,
        BFormInvalidFeedback,
        BButton,
        BForm,
        BFormInput,

        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            value: null,
            textarea: null,
            valueRadio: null,
            optionsRadio: [
                { text: 'Review', value: 'Review' },
                { text: 'Video di Lokasi', value: 'Video di Lokasi' },
                { text: 'Video dengan Produk', value: 'Video dengan Produk' },
                { text: 'Lainnya', value: 'Lainnya' },
            ],
            valueRadioKirim: null,
            optionsRadioKirim: [
                { text: 'Ya', value: true },
                { text: 'Tidak', value: false }
            ],
            state: true,
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { "My-Awesome-Header": "header value" },
                thumbnailWidth: 200,
                addRemoveLinks: true,
                dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD ME"
            },

            // validation rules
            required,
            email,

            startDate: null,
            endDate: null,
            contentDeadline: null,
            description: null,
            sample: null,
            contentType: null,
            mediaGuideline: null,
            captionGuideline: null,
            receiptNumber: null,
            reference: null,
            additionalInfo: null,

            referenceErrMsg: null,
            contentDeadlineErrMsg: null,

            deadlineMinDate: null,
            minStartDate: null,
            minEndDate: null,
            campaign_type: null,
            customMessages: {
                required: 'Harus diisi'
            },
            init: true
        }
    },
    methods: {
        step3() {
            let _ = this
            this.$refs.step3.validate().then(success => {
                if (success) {
                    let model = {
                        id: localStorage.getItem('campaignID'),
                        startDate: _.startDate,
                        endDate: _.endDate,
                        contentDeadline: _.contentDeadline,
                        description: _.description,
                        sample: _.sample,
                        contentType: _.contentType,
                        mediaGuideline: _.mediaGuideline,
                        captionGuideline: _.captionGuideline,
                        receiptNumber: _.receiptNumber,
                        reference: _.reference,
                        additionalInfo: _.additionalInfo
                    }
                    
                    axios.post('/advertiser/campaigns/brief-content', model)
                        .then(resp => {
                            let success = resp.data.success
                            if ( success ) {
                                if ( _.$route.name == 'campaign-edit-3' ) {
                                    _.$router.push({name: 'campaign-edit-4'})
                                } else {
                                    _.$router.push('step-4')
                                }
                            }
                        })
                        .catch(err => {
                            if ( err.response.data ) {
                                console.log(err.response.data)
                                if ( err.response.data.error_code == 422 ) {
                                    err.response.data.message.errors.forEach(dt => {
                                        console.log(dt.param)
                                        if ( dt.param == 'reference' ) {
                                            _.referenceErrMsg = dt.msg
                                        }

                                        if ( dt.param == 'contentDeadline' ) {
                                            _.contentDeadlineErrMsg = dt.msg
                                        }
                                    })
                                }
                            }
                        })
                } else {
                    console.log('failed validation')
                }
            })
        },
        getCampaignData() {
            let _ = this
            axios.get('/advertiser/campaigns/' + _.$route.params.id)
                .then(resp => {
                    let _ = this
                    let campaign = resp.data.data
                    localStorage.setItem('campaignID', campaign.id)
                    _.startDate = campaign.startDate
                    _.endDate = campaign.endDate
                    _.contentDeadline = campaign.contentDeadline
                    _.description = campaign.description
                    _.sample = campaign.sample
                    _.contentType = campaign.contentType
                    _.mediaGuideline = campaign.mediaGuideline
                    _.captionGuideline = campaign.captionGuideline
                    _.receiptNumber = campaign.receiptNumber
                    _.reference = campaign.reference
                    _.additionalInfo = campaign.additionalInfo
                    _.campaign_type = campaign.campaignType
                    // _.additionalInfo ??
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        back(){
            let _ = this
            let campaignID = localStorage.getItem('campaignID') || _.$route.params.id
            // let nextPage = _.campaign_type == 'Project' ? 'campaign-create-2-project' : 'campaign-create-2-assistant'

            // if ( _.$route.name == 'campaign-edit-3' ) {
            //     _.$router.push({name: 'campaign-edit-1', params: {id: _.$route.params.id }})
            // } else {
            //     _.$router.push({name: 'campaign-create-1', params: {id: localStorage.getItem('campaignID')}})
            // }

            // if ( _.$route.name == 'campaign-edit-3' ) {
                let nextPage = _.campaign_type == 'Project' ? 'campaign-edit-2-project' : 'campaign-edit-2-assistant'
                _.$router.push({name: nextPage, params: {id: campaignID}})
            // } else {
                // _.$router.push({name: nextPage, params: {id: localStorage.getItem('campaignID')}})
            // }

            console.log(nextPage)
        }
    },
    mounted() {
        let _ = this

        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        const defaultDate = new Date(today)
        minDate.setDate(minDate.getDate() + 3)
        defaultDate.setDate(defaultDate.getDate() + 7)
        _.deadlineMinDate = minDate
        _.contentDeadline = defaultDate

        if ( _.$route.name == 'campaign-edit-3' ) {
            _.getCampaignData()
            _.init = true
        }

        _.campaign_type = localStorage.getItem('campaignType')
    },
    watch: {
        reference: function(newValue, oldValue) {
            this.referenceErrMsg = null
        },
        contentDeadline: function(newValue, oldValue) {
            this.contentDeadlineErrMsg = null
            if ( newValue ) {
                const minStartDate = new Date(newValue)
                minStartDate.setDate(minStartDate.getDate() + 1)

                this.minStartDate = minStartDate
                if ( this.init ) {
                    this.init = false
                } else {
                    // this.startDate = null
                }

                if ( this.startDate ) {
                    let cd = new Date(newValue)
                    let sd = new Date(this.startDate)

                    if ( cd >= sd ) {
                        this.startDate = null
                    }
                }
            }
        },
        startDate: function(newValue, oldValue) {
            if ( newValue ) {
                const minEndDate = new Date(newValue)
                minEndDate.setDate(minEndDate.getDate() + 1)

                this.minEndDate = minEndDate
                if ( this.init ) {
                    this.init = false
                } else {
                    // this.endDate = null
                }

                if ( this.endDate ) {
                    let sd = new Date(newValue)
                    let ed = new Date(this.endDate)

                    if ( sd >= ed ) {
                        this.endDate = null
                    }
                }
            } else {
                this.endDate = null
            }
        }
    }
}
</script>