var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-card',[_c('b-card-header',[_c('h4',{staticClass:"text-center"},[_vm._v("Brief Konten")])]),_c('b-card-body',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.back()}}},[_vm._v("Back")]),_c('hr'),_c('validation-observer',{ref:"step3"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.step3($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Deadline Konten","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Deadline Konten","vid":"deadline-datepicker","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-0",attrs:{"id":"deadline-datepicker","name":"deadline-datepicker","state":(errors.length > 0 || _vm.contentDeadlineErrMsg) ? false:null,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"title-format":"DD-MM-YYYY","min":_vm.deadlineMinDate},model:{value:(_vm.contentDeadline),callback:function ($$v) {_vm.contentDeadline=$$v},expression:"contentDeadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.contentDeadlineErrMsg))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Campaign Dimulai","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Campaign Dimulai","vid":"start-datepicker","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-0",attrs:{"id":"start-datepicker","name":"start-datepicker","state":errors.length > 0 ? false:null,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"title-format":"DD-MM-YYYY","min":_vm.minStartDate},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Campaign Selesai","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Campaign Selesai","vid":"end-datepicker","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-0",attrs:{"id":"end-datepicker","name":"end-datepicker","state":errors.length > 0 ? false:null,"date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"title-format":"DD-MM-YYYY","min":_vm.minEndDate},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Brief/Deskripsi Konten","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Brief/Deskripsi Konten","vid":"description","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"mb-0",attrs:{"id":"description","name":"description","state":errors.length > 0 ? false:null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tipe Konten","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tipe Konten","vid":"contentType","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"id":"contentType","name":"contentType","options":_vm.optionsRadio,"state":errors.length > 0 ? false:null},model:{value:(_vm.contentType),callback:function ($$v) {_vm.contentType=$$v},expression:"contentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Apakah Kamu Memerlukan Kirim Barang ke Influencer?","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Kirim Barang","vid":"sample","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"id":"sample","name":"sample","options":_vm.optionsRadioKirim,"state":errors.length > 0 ? false:null},model:{value:(_vm.sample),callback:function ($$v) {_vm.sample=$$v},expression:"sample"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.sample),expression:"sample"}],staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"No. Resi","label-for":"basicInput"}},[_c('b-form-input',{staticClass:"mb-1",model:{value:(_vm.receiptNumber),callback:function ($$v) {_vm.receiptNumber=$$v},expression:"receiptNumber"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Aturan Foto/Video","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Aturan Foto/Video","vid":"mediaGuideline","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"mb-0",attrs:{"id":"mediaGuideline","name":"mediaGuideline","state":errors.length > 0 ? false:null},model:{value:(_vm.mediaGuideline),callback:function ($$v) {_vm.mediaGuideline=$$v},expression:"mediaGuideline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Aturan Caption","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Aturan Caption","vid":"captionGuideline","rules":"required","custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"mb-0",attrs:{"id":"captionGuideline","name":"captionGuideline","state":errors.length > 0 ? false:null},model:{value:(_vm.captionGuideline),callback:function ($$v) {_vm.captionGuideline=$$v},expression:"captionGuideline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Referensi Foto/Video","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Referensi Foto/Video","vid":"reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reference","name":"reference","state":(errors.length > 0 || _vm.referenceErrMsg) ? false:null},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}}),_c('small',[_c('i',[_vm._v("Silahkan upload dokumen brief konten, referensi video dan foto dalam satu link google drive")])]),_c('small',{staticClass:"text-danger"},[_c('br'),_vm._v(_vm._s(errors[0] || _vm.referenceErrMsg))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Informasi Lainnya (Optional)","label-for":"basicInput"}},[_c('b-form-textarea',{model:{value:(_vm.additionalInfo),callback:function ($$v) {_vm.additionalInfo=$$v},expression:"additionalInfo"}}),_c('small',[_c('i',[_vm._v("Informasi lainnya hanya dapat dilihat oleh admin PT DMMX RANS DIGITAL")])])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","block":"","variant":"primary"}},[_vm._v(" Selanjutnya ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }